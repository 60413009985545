@import './minireset.less';
@import '../../../node_modules/ng-zorro-antd/resizable/style/entry.less';

html,
body {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

// Typography
@heading-1-size: 20px;
@heading-2-size: 16px;
@heading-3-size: 14px;
@heading-4-size: 12px;
@heading-5-size: 10px;

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
  line-height: 1.4;
  margin-bottom: 0;
  margin-top: 0 !important;
}

div.ant-typography {
  margin-bottom: 0;
}

.ant-typography ul,
.ant-typography ol {
  margin-bottom: 0;
}

@line-height-base: 1.6;

// Generic
@height-base: 40px;
@height-lg: 46px;
@height-sm: 32px;

.height-base {
  height: @height-base;
}

@border-radius-base: 8px;
@border-radius-sm: 4px;

// Layout
@layout-header-height: auto;
@layout-header-padding: 0;

@layout-footer-padding: 10px 16px;

// Buttons
@btn-font-weight: 600;
@btn-line-height: 1;
@btn-padding-horizontal-base: @padding-lg;
@btn-height-sm: 32px;
@btn-padding-horizontal-sm: @padding-sm;

@btn-font-size-lg: @font-size-base;
@btn-font-size-sm: @font-size-sm;

@btn-text-shadow: none;
@btn-text-hover-bg: transparent;

.ant-btn-link:not(.ant-btn-icon-only),
.ant-btn-text:not(.ant-btn-icon-only) {
  padding: 0 @padding-md !important;
}

::ng-deep a[nz-button][nzType='link'].card-action-button {
  margin-right: -16px !important;
}

// Radio buttons
@radio-button-bg: @neutral-2;

.ant-radio-button-wrapper {
  font-weight: @btn-font-weight;
}

.ant-radio-group-small > .ant-radio-button-wrapper {
  font-size: @font-size-sm;
}

.ant-btn > span {
  display: inline-flex;
}

.floating-button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: @padding-md;
  z-index: 1000;
  border: solid 1px;
  margin: auto;

  button {
    width: 100%;
  }
}

//Card
@card-padding-base: @padding-md;
@card-padding-base-sm: @padding-sm;

//Collapse
@collapse-header-padding: @padding-md; //16px
@collapse-content-padding: @padding-md;

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-flex;
  color: @neutral-6;
}

//Colapse as menu items
.app-service-menu-item {
  .ant-card-body,
  .ant-collapse-header {
    padding: 8px 0 !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: @padding-xs;
  }

  > .ant-collapse-content {
    background-color: @neutral-1;
  }
}

//Calendar
nz-calendar-header {
  border: solid 1px;
  border-bottom: none;
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;

  .ant-flex-align-normal {
    align-items: center; /* sorry, did not manage otherwise */
  }
}

.ant-picker-calendar-mini .ant-picker-panel {
  border: solid 1px;
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
}

@picker-panel-cell-height: 28px;

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
}

//avatar
@avatar-size-sm: 22px;

.ant-avatar-lg {
  font-size: large;
}

// Menu
@menu-inline-toplevel-item-height: 46px;
@menu-item-height: 46px;
@menu-collapsed-width: 70px;
@menu-item-active-border-width: 0;
@menu-item-boundary-margin: @margin-xs;
@menu-item-vertical-margin: 0;
@menu-item-padding-horizontal: 0px;
@menu-icon-margin-right: @margin-sm;
@menu-icon-size: 18px;

.ant-menu {
  padding: @padding-sm;
}

.ant-menu-inline,
.ant-menu-vertical {
  border: 0;
}

.ant-menu-item,
.ant-menu-submenu {
  padding: 0;
  font-weight: 600;
  border-radius: @border-radius-sm;
}

.ant-menu-submenu-title {
  margin-bottom: 8px !important;
}

.ant-menu-item:hover {
  .anticon {
    color: @primary-6;
    transition: color 1s ease-in-out;
  }
}

.ant-menu-item-selected {
  .anticon {
    color: @primary-6;
  }
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  font-size: @menu-icon-size;
}

.ant-menu-inline-collapsed .myprofile-submenu .ant-menu-submenu-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  .full-name {
    display: none;
  }
}
.ant-menu-inline-collapsed .myprofile-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.ant-layout-sider-collapsed {
  width: @menu-collapsed-width !important;
  min-width: @menu-collapsed-width !important;
  max-width: @menu-collapsed-width !important;
}

//checkbox
@checkbox-size: 18px;
@checkbox-border-radius: @border-radius-sm;

//radio
@radio-size: 18px;
@radio-wrapper-margin-right: 0px;

//forms
@form-item-label-height: inherit;
@form-item-margin-bottom: 0;
@form-vertical-label-padding: 0 0 @padding-xss;

.ant-form-item-explain-error {
  margin-bottom: 8px;
}

//Dropdown
@dropdown-vertical-padding: @padding-xs;
@dropdown-edge-child-vertical-padding: @padding-xs;
@dropdown-line-height: 16px;

.ant-dropdown-menu {
  padding-left: @padding-xs;
  padding-right: @padding-xs;
}

.ant-dropdown-menu-item {
  padding-left: @padding-xs;
  padding-right: @padding-lg;
  border-radius: @border-radius-base;

  .anticon {
    margin-right: 4px;
  }
}

// Date picker
// .floating-label nz-date-picker .ant-picker-input {
//   padding: 24px 6px 2px 6px;
// }

@media (max-width: @screen-md) {
  .ant-picker-wrapper {
    display: none;
  }
}

// Floating labels

select.floating-label,
input.floating-label {
  height: 68px;
}

.label {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  pointer-events: none;
  z-index: 2;
}

nz-form-label.as-label,
label.as-label {
  top: 33px;
  transform: translateY(-100%);
}

.as-placeholder.ant-form-item-label {
  padding: 0px;
}

.as-label.ant-form-item-label > label {
  font-size: 12px;
  padding: 0px;
}

.input-not-empty.ant-input {
  padding: 34px 16px 12px 16px;
}

.ant-form-item-control-input-content.floating-label {
  position: relative;
  display: block;
}

.floating-label .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 68px;
  padding: 0 16px;
}

.floating-label
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 88px;
  padding: 0 4px;
}

.ant-select-arrow {
  right: 16px;
}

nz-select .ant-select-selection-item {
  margin-top: 25px;
}

nz-select.select-item-small.ant-select-single .ant-select-selection-item {
  margin-top: 0;
}

// Dropdown
@media (max-width: @screen-sm-max) {
  //dropdown needs to be shown on 768
  .app-mobile-hide-options .ant-select-dropdown {
    display: none;
  }
}

// Tabs
@media (max-width: @screen-sm-max) {
  .ant-tabs-nav {
    display: none !important;
  }
  .app-show-tabs-on-mobile .ant-tabs-nav {
    display: block !important;
  }
}

nz-tabset {
  height: 100%;
}

.ant-tabs-content {
  height: 100%;
}

// Drawer content padding on mobile (iOS)
.uploadcare--dialog_status_active,
.ant-drawer-content {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

// drawer as action sheets
.botom-menu-mobile {
  .ant-drawer-content {
    border-radius: 16px 16px 0 0;
    padding-top: 0;
  }

  .ant-drawer-header {
    border-bottom: 0;
  }

  .ant-drawer-body {
    padding: 0px 8px 8px 8px;
  }
}

//alert
.ant-alert-error {
  border: none;
}

.ant-modal-header {
  border-bottom: none;
}
.ant-modal-footer {
  border-top: none;
}

@media (min-width: 992px) {
  .floating-button {
    display: none;
  }
}

// Tabs
.ant-tabs-left > .ant-tabs-nav {
  // min-width: 232px;
  width: 20%;
}

// time-picker
.ant-form-item-control-input-content.floating-label .ant-picker {
  height: 68px;
  padding: 0 16px;
  width: 100%;
  padding: 34px 16px 12px 16px;
}

.ant-picker-ranges > {
  text-align: center;

  li.ant-picker-now {
    display: none;
  }

  li.ant-picker-ok {
    float: none;
  }
}

.ant-picker .ant-picker-suffix {
  display: none;
}

.ant-picker .ant-picker-clear {
  display: none;
}

// Responsive tables
nz-table.responsive-table .ant-table-content {
  overflow-x: auto;
}

// Mobile app
.app-safe-area-inset-top {
  padding-top: env(safe-area-inset-top);
}
//iOS
.app-safe-area-inset-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

//Android
html.plt-android .app-safe-area-inset-top {
  padding-top: 24px;
}

@tabs-vertical-padding: @padding-xs @padding-lg @padding-xs 0;
@tabs-horizontal-padding: 0 0 @padding-sm 0;
@tabs-horizontal-gutter: @margin-lg;
@tabs-bar-margin: 0 0 @margin-lg 0;

@neutral-9: #000000;
@neutral-8: #1f1f1f;
@neutral-7: #595959;
@neutral-6: #8c8c8c;
@neutral-5: #bfbfbf;
@neutral-4: #d9d9d9;
@neutral-3: #f0f0f0;
@neutral-2: #f5f5f5;
@neutral-1: #fafafa;
@neutral-0: #ffffff;

// Full calendar
.fc-v-event {
  background-color: transparent;
  border: none;
}

.app-experts-avatars {
  .ant-select .ant-select-selection-search-input {
    caret-color: transparent;
  }

  .ant-select-selector {
    cursor: pointer !important;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      height: 45px !important;
      line-height: 45px !important;
      padding: 0 !important;
      margin-inline-end: -9px !important;
      background: none !important;
      border: none !important;
      // box-shadow: none !important;
    }

    .ant-select-selection-item-remove {
      display: none;
    }
  }

  .as-placeholder.ant-form-item-label {
    display: none;
  }

  .ant-select-selector {
    padding: 0 !important;
  }
}

// Table
nz-table.responsive-table .ant-table-content {
  overflow-x: auto;
}

.app-hide-table-sort .ant-table-column-sorter {
  display: none !important;
}
