/* @import "~@ionic/angular/css/ionic.bundle.css"; */

@import './themes/default.less';
// @primary-color-rbg-red: red(@primary-color);
// @primary-color-rbg-green: green(@primary-color);
// @primary-color-rbg-blue: blue(@primary-color);

@rgb-primary-color: ~'rgb(red(@{primary-color}), green(@{primary-color}), blue(@{primary-color}))';

:root {
  --ion-safe-area-inset-top: env(safe-area-inset-top);
  --ion-safe-area-inset-bottom: env(safe-area-inset-bottom);
}

ion-datetime {
  --background: rgb(255, 255, 255);
  --background-rgb: 255, 255, 255;
  --wheel-highlight-background: @neutral-1;
  --wheel-highlight-border-radius: 0px;
  --wheel-fade-background-rgb: 255, 255, 255;
}

ion-datetime::part(wheel-item) {
  color: @neutral-8;
}

ion-datetime::part(wheel-item active) {
  color: @primary-color;
}

ion-datetime::part(calendar-day today) {
  color: @neutral-8;
  border-radius: 50%;
  background-color: @neutral-0;
  border: @primary-color 1px solid;
}

ion-datetime::part(calendar-day):focus {
  box-shadow: none;
}

ion-datetime::part(calendar-day active),
ion-datetime::part(calendar-day active):focus {
  color: @neutral-1;
  border-radius: 50%;
  background-color: @primary-color;

  font-weight: 400;
  font-size: 20px;
}
