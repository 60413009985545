/* Import Tailwind CSS */
@tailwind base;
/* @tailwind components; */
@tailwind utilities;

body {
  line-height: 1.6;
  position: static;
  overflow: auto;
}
