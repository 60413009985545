@import (multiple) '../../../node_modules/ng-zorro-antd/src/style/themes/variable.less';
@import './base.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// Theme customizations

@blue-6: #0050ff;
@red-6: #cf1322;

@primary-color-hover: @primary-5;
@primary-color-active: @primary-7;
@error-color: @red-6;
@info-color: @blue-6;
@warning-color: @orange-6;

@primary-0: ~'var(--@{ant-prefix}-primary-0)';
@primary-0: ~'rgb(from @{primary-1} r g b / 50%)'; //come back to this
@primary-2: ~'rgb(from @{primary-3} r g b / 30%)';

//Text colors
@text-color: @neutral-8;
@text-color-secondary: @neutral-6;
@heading-color: @neutral-8;

.ant-form-item-explain-error {
  font-size: @font-size-sm;
}

// General
@layout-header-background: @neutral-0;
@layout-body-background: @neutral-1;
@layout-sider-background: @neutral-0;
@body-background: @neutral-0;
@layout-footer-background: @neutral-0;
@disabled-bg: @neutral-3;

@border-color-base: @neutral-3;
@border-color-split: @neutral-3;

@disabled-color: @neutral-5;
@disabled-bg: @neutral-3;

@component-background: @neutral-0;
@picker-basic-cell-disabled-bg: @component-background;

// @layout-header-background: #001033;
// @menu-dark-bg: #001033;

@menu-inline-submenu-bg: @neutral-0;

.ant-menu-item .anticon {
  color: @neutral-6;
}

// Buttons
@btn-default-color: @primary-color;
@btn-default-border: @primary-color;

.ant-btn-primary:disabled {
  border: @neutral-3;
  background-color: @neutral-3;
}

.ant-btn-icon-only {
  background-color: transparent;
}

.ant-btn-text.ant-btn-icon-only {
  color: @neutral-6;
}

.ant-btn-text:hover {
  color: @primary-color-hover;
}

// Collapse
@collapse-header-bg: @neutral-0;

// .ant-collapse-arrow {
//   color: @neutral-6;
// }

// .ant-collapse-content-box {
//   color: @neutral-7;
// }

.floating-button {
  background-color: @neutral-0;
  border-color: @neutral-3;
}

.time-card {
  .time-slot {
    border-color: @neutral-4;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    background: @primary-0;
    color: @primary-6;
    border-color: @neutral-4;
  }
}

// Card
.ant-card-cover {
  background-color: @neutral-2;
}

// .ant-card-hoverable:hover {
//   box-shadow: none;
// }
@card-shadow: 0;

.ant-card-hoverable:hover {
  border-color: @primary-6;
  background-color: @primary-0;

  // h3 {
  //   color: @primary-6;
  // }
}

// List
.ant-list-item {
  background-color: @neutral-0;
  color: @neutral-7;
  border: 1px solid @neutral-3;
}

.ant-list-item:last-child {
  border-bottom: 1px solid @neutral-3 !important;
}

//Calendar
nz-calendar-header {
  border-color: @border-color-base;
  background-color: @component-background;
}

.ant-picker-calendar-mini .ant-picker-panel {
  border-color: @border-color-base;
}

//Radio
.ant-radio-inner {
  border-color: @neutral-5;
}

//Default checkobox
.ant-checkbox-inner {
  border-color: @neutral-5;
}

// Floating labels

.as-placeholder.ant-form-item-label > label,
.as-label.ant-form-item-label > label {
  color: @neutral-6;
}

.ant-form-item-label > label.text-danger {
  color: @error-color;
}

.ant-form-item-label > label.focused {
  color: @primary-color;
}

//Unsorted

.header {
  border-color: @border-color-base !important;
}

.bg-primary-0 {
  background-color: @primary-0;
}

.bg-primary-1 {
  background-color: @primary-1;
}

.bg-primary-2 {
  background-color: @primary-2;
}

.bg-neutral-0 {
  background-color: @neutral-0;
}

.text-primary {
  color: @primary-color;
}

.text-danger {
  color: @error-color;
}

@media (max-width: @screen-md-max) {
  .top-navigation {
    background-color: @neutral-0;
    border-bottom: solid 1px;
    border-color: @neutral-3;
  }
}

//drawer
@drawer-header-padding: @margin-md;
@drawer-body-padding: @margin-md;

// drawer left mobile
.left-menu-mobile {
  .ant-drawer-body {
    background-color: @neutral-1;
  }
}

// drawer right
.responsiveDrawerWidth {
  width: 100% !important;

  .ant-drawer-body {
    background-color: @neutral-1;
  }
}

.drawer-form {
  .ant-drawer-title {
    margin-right: 28px;
    text-align: center;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    // all the components rendered in a drawer must take full height
    > * {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      // the form inside must also take full height
      form {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;

        // the form content should take full height, except for the button which is rendered with a fixed width at the end
        .app-form-content {
          flex: 1;
          overflow-y: auto;
          padding: 16px;
        }
      }
    }

    .ant-tabs-nav {
      padding: 16px 16px 0 16px;
    }

    // expand Tabs layouts to full height and proper column orientation
    .ant-tabs-tabpane {
      height: 100%;
      display: flex;
      flex-direction: column;

      // all the components rendered in the tabs must take full height
      > *:first-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;

        .app-form-content {
          padding-top: 0;
        }
      }
    }
  }
}

@media (min-width: @screen-md) {
  .responsiveDrawerWidth {
    width: 400px !important;

    .ant-drawer-header {
      border-bottom: 0;
      background-color: @neutral-1;
    }
  }
}

@media (min-width: @screen-lg) {
  .responsiveDrawerWidth {
    width: 400px !important;
  }
}

@media (min-width: @screen-xl) {
  .responsiveDrawerWidth {
    width: 500px !important;
  }
}

//this is a bug fixing attempt: when scrolling on mobile, the input fields in Business details and the cards in Team Management get a hover effect;
//should be replaced with a better solution that kills all unwanted interactions when scrolling on mobile
@media (hover: none) and (pointer: coarse) {
  .ant-input:hover,
  .ant-select:hover .ant-select-selector,
  .ant-card-hoverable:hover {
    border-color: @border-color-base;
    background-color: @component-background;
    box-shadow: none;
  }
}

.app-experts-avatars {
  .ant-select .ant-select-selection-search-input {
    caret-color: transparent;
  }

  .ant-select-selector {
    cursor: pointer !important;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      height: 45px !important;
      line-height: 45px !important;
      padding: 0 !important;
      margin-inline-end: -9px !important;
      background: none !important;
      border: none !important;
      // box-shadow: none !important;
    }

    .ant-select-selection-item-remove {
      display: none;
    }
  }

  .as-placeholder.ant-form-item-label {
    display: none;
  }

  .ant-select-selector {
    padding: 0 !important;
  }
}

// Client Table
.app-client-table thead th {
  background-color: inherit !important;
  color: inherit !important;
  border-bottom: inherit !important;
}

.app-client-table .ant-table-thead > tr > th::before {
  display: none !important;
}

.app-client-table .ant-table-tbody > tr.ant-table-row:hover > td,
.app-client-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: @primary-0 !important;
}
